import { ref } from '@vue/composition-api'
import { isNavLinkActive, navLinkProps } from '@core/layouts/utils'
import store from '@/store'

export default function useVerticalNavMenuLink(item) {
  const isActive = ref(false)

  const linkProps = navLinkProps(item)

  const updateIsActive = () => {
    isActive.value = isNavLinkActive(item)
  }

  const bindingChild = () => {
    store.commit('verticalMenu/UPDATE_CHILDREN_SHOW', false)
    if (item.isLevel1) {
      store.commit('verticalMenu/UPDATE_CHILDREN_MENU', item.children !== undefined ? item.children : [])
      store.commit('verticalMenu/UPDATE_CHILDREN_SHOW', true)
    }
  }

  return {
    isActive,
    linkProps,
    updateIsActive,
    bindingChild,
  }
}
