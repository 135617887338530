<template>
  <b-nav-item-dropdown
    v-if="userData"
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="user-dropdown"
  >
    <template #button-content>
      <div class="d-sm-flex d-none">
        <span class="user-name font-weight-bolder pr-50">
          <template v-if="userData.userName">
            {{ userData.userName }}
          </template>
        </span>
      </div>

      <b-avatar
        size="22"
        variant="light"
        class="badge-minimal"
      >
        <!-- <feather-icon

          icon="UserIcon"
          size="22"
          color="#000"
        /> -->
      </b-avatar>
    </template>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="changePassWord"
    >
      <span> {{ $t('nav_change_pass_word') }}</span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item>
    <ChangePassWord />
  </b-nav-item-dropdown>
</template>

<script>
import ChangePassWord from '@/@core/layouts/components/app-navbar/components/changePassWord.vue'
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
// import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    ChangePassWord,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
    }
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      // localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      // localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('foToken')
      localStorage.removeItem('userGolfData')
      localStorage.removeItem('currentSiteId')
      localStorage.removeItem('timeZone')
      localStorage.removeItem('timeZoneOffset')
      localStorage.removeItem('Permission')
      localStorage.removeItem('numberOfHole4Caddy')
      localStorage.removeItem('isDefaultCaddyChargeFee')
      localStorage.removeItem('module')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
    changePassWord() {
      this.$bvModal.show('modal-changePassWord')
    },
  },
}
</script>
