<template>
  <div class="nav-menu-left">
    <div
      class="main-menu menu-fixed menu-accordion menu-shadow"
      :class="[
        { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
        skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
      ]"
      @mouseenter="updateMouseHovered(true)"
      @mouseleave="updateMouseHovered(false)"
    >
      <!-- Shadow -->
      <div
        :class="{'d-block': shallShadowBottom}"
        class="shadow-bottom"
      />

      <!-- main menu content-->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="main-menu-content scroll-area"
        tagname="ul"
        @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
      >
        <vertical-nav-menu-items
          :items="(navMenuItems[rootRoute].children.length > 0) ? navMenuItems[rootRoute].children : navMenuItems.filter( ( x, index) => index == rootRoute)"
          class="navigation navigation-main"
        />
      </vue-perfect-scrollbar>
      <div
        class="ez-fixed-toggle-menu text-truncate"
      >
        <b-link
          class="nav-link"
          @click="toggleCollapsed"
        >
          <feather-icon
            :icon="collapseTogglerIconFeather"
            size="20"
            class="collapse-toggle-icon"
          />
          <span class="collapse-toggle-icon">{{ isVerticalMenuCollapsed ? '' : $t('collapse') }}</span>
        </b-link>
      </div>
      <!-- /main menu content-->
      <div class="navbar-header expanded">
        <slot
          name="header"
          :toggleVerticalMenuActive="toggleVerticalMenuActive"
          :toggleCollapsed="toggleCollapsed"
          :collapseTogglerIcon="collapseTogglerIcon"
        >
          <ul class="nav navbar-nav">
            <!-- Toggler Button -->
            <li class="nav-item">
              <b-link
                class="nav-link"
                @click="toggleCollapsed"
              >
                <feather-icon
                  :icon="collapseTogglerIconFeather"
                  size="20"
                  class="collapse-toggle-icon"
                />
                <span class="collapse-toggle-icon">{{ isVerticalMenuCollapsed ? '' : $t('collapse') }}</span>
              </b-link>
            </li>
          </ul>
        </slot>
      </div>
    </div>
    <div
      v-show="isShowChild"
      class="second-menu menu-fixed expanded"
      :class="[
        skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
      ]"
      @mouseleave="updateMouseHoveredSec(false)"
    >
      <!-- second menu content-->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="main-menu-content scroll-area"
        tagname="ul"
        @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
      >
        <vertical-nav-menu-items
          :items="childrenMenu"
          class="navigation navigation-main"
        />
      </vue-perfect-scrollbar>
    <!-- /second menu content-->
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import navMenuItems from '@/navigation/vertical'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
      updateMouseHoveredSec,
      childrenMenu,
      isShowChild,
      rootRoute,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'ChevronsRightIcon' : 'ChevronsLeftIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      rootRoute,
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      updateMouseHoveredSec,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
      childrenMenu,
      isShowChild,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
