<template>
  <b-nav-item-dropdown
    v-if="courses && courses.Site"
    class="courses-dropdown"
    :text="courses.Site.find( x => x.SiteId == courseSelected).SiteName"
    variant="outline-secondary"
  >
    <b-dropdown-item
      v-for="item in courses.Site"
      :key="item.SiteId"
      @click="changeCourse(item.SiteId)"
    >
      {{ item.SiteName }}
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import { commonServices } from '@/api/common-services'

export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      courseSelectedText: 'Course 1',
      courseSelected: JSON.parse(localStorage.getItem('currentSiteId')),
      courses: JSON.parse(localStorage.getItem('userGolfData')),
    }
  },
  methods: {
    async changeCourse(siteId) {
      await commonServices
        .switchSite({
          SiteId: siteId,
        })
        .then(res => {
          if (res.Status == '200') {
            localStorage.setItem('userGolfData', JSON.stringify({
              ...res.Data,
            }))

            const oldUserData = JSON.parse(localStorage.getItem('userData'))

            oldUserData.hotelId = this.courses.Site.find(x => x.SiteId == siteId).HotelId

            localStorage.setItem('userData', JSON.stringify(
              oldUserData,
            ))

            localStorage.setItem('currentSiteId', JSON.stringify(
              siteId,
            ))

            localStorage.setItem('timeZone', res.Data.Site.find(x => x.SiteId == siteId).TimeZone)

            localStorage.setItem('timeZoneOffset', res.Data.Site.find(x => x.SiteId == siteId).TimeZoneOffset)
          } else {
            this.showResToast(res)
          }
        })

      await commonServices
        .getCommon({ KeyGroup: 'BOOKING_POLICY' })
        .then(res => {
          if (res.Status == '200') {
            if (res.Data.filter(x => x.KeyCode == 'NUMBER_OF_HOLE_4_CADDY').length > 0) {
              localStorage.setItem('numberOfHole4Caddy', res.Data.find(x => x.KeyCode == 'NUMBER_OF_HOLE_4_CADDY').KeyValue)
            } else {
              localStorage.setItem('numberOfHole4Caddy', '')
            }
          }

          this.$router.go()
        })
    },
  },
}
</script>
