import { i18n } from '@/libs/i18n'

export default [
  {
    title: i18n.tc('golf_nav_starter'),
    isLevel1: true,
    hassub: true,
    isMain: true,
    children: [
      {
        title: 'Lễ tân',
        route: '',
        isLevel1: true,
        hassub: true,
        icon: 'ezGolf-icon-FO',
        children: [
          {
            header: 'Lễ tân',
          },
          {
            title: 'Tee Time',
            route: 'tee-sheet',
          },

          {
            title: 'Tee Sheet',
            route: 'booking',
          },
          {
            title: 'Sân tập',
            route: 'booking-driving-range',
          },
          {
            title: 'Tham quan',
            route: 'visitor',
          },
        ],
      },
      {
        title: 'Locker',
        route: '',
        icon: 'ezGolf-icon-locker',
      },
      {
        title: 'Starter',
        route: 'starter',
        icon: 'ezGolf-icon-whistle-outline',
      },
      {
        title: i18n.tc('golf_nav_starter_caddy'),
        route: '',
        isLevel1: true,
        hassub: true,
        icon: 'ezGolf-icon-golf-bag',
        children: [
          {
            header: i18n.tc('golf_nav_starter_caddy'),
          },
          {
            title: 'Lịch làm việc caddy',
            route: 'caddy-calendar',
          },
          {
            title: 'Đánh giá caddy',
            route: '',
          },
          {
            title: 'Báo cáo đánh giá caddy',
            route: '',
          },
          {
            title: i18n.tc('golf_nav_starter_caddy_management'),
            route: 'caddy-setting',
          },
        ],
      },
      {
        title: 'Khu kinh doanh',
        route: '',
        isLevel1: true,
        hassub: true,
        icon: 'ezGolf-icon-map',
        children: [
          {
            header: 'Khu kinh doanh',
          },
          {
            title: 'Đặt chỗ',
            route: '',
          },
          {
            title: 'Hội viên',
            route: '',
            children: [
              {
                title: 'Chuyển nhượng',
                route: '',
              },
              {
                title: 'Phí thường niên',
                route: '',
              },
              {
                title: 'Quản lý thẻ hội viên',
                route: 'member-card',
              },
              {
                title: 'Quản lý hội viên',
                route: 'members',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: i18n.tc('golf_nav_restaurance'),
    icon: 'BookIcon',
    isLevel1: true,
    hassub: true,
    isMain: true,
    children: [
      {
        title: 'Updating',
        route: '',
      },
    ],
  },
  {
    title: i18n.tc('golf_nav_report'),
    icon: 'BookIcon',
    isLevel1: true,
    hassub: true,
    isMain: true,
    children: [
      {
        title: 'Updating',
        route: '',
      },
    ],
  },
  {
    title: i18n.tc('golf_nav_setting'),
    icon: 'ezGolf-icon-golf-course',
    isLevel1: true,
    hassub: true,
    isMain: true,
    children: [
      {
        title: i18n.tc('golf_nav_setting'),
        route: '',
        icon: 'ezGolf-icon-golf-course',
        isLevel1: true,
        hassub: true,
        children: [
          {
            header: i18n.tc('golf_nav_setting'),
          },
          {
            title: i18n.tc('golf_nav_setting_golf_class'),
            route: 'golf-class',
          },
          {
            title: i18n.tc('golf_nav_setting_course'),
            route: 'course-setting',
          },
          {
            title: i18n.tc('golf_nav_setting_driving_range'),
            route: 'driving-range-setting',
          },
        ],
      },
      {
        title: i18n.tc('golf_nav_package'),
        route: 'package',
        icon: 'ezGolf-icon-tag',
      },
      {
        title: i18n.tc('golf_nav_ratecode'),
        route: 'rate-code',
        icon: 'ezGolf-icon-shopping-bag',
      },
      {
        title: 'Thống kê',
        route: '',
        icon: 'ezGolf-icon-chart-outlined',
      },
    ],
  },
]
