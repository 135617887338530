<template>
    <div>
        <b-modal
            id="modal-changePassWord"
            size="md"
            centered
            hide-header
            cancel-variant="none"
            ok-variant="primary"
            :no-close-on-backdrop="true"
            v-model="isOpen"
        >
            <b-card-header class="banned-background-color-primary">
                <div class="d-flex justify-content-between align-items-center block-header">
                    <p class="mb-0 title-modal color-primary">
                        Đổi mật khẩu
                    </p>
                    <feather-icon
                    @click="hideModal"
                    icon="XIcon"
                    size="24"
                    class="cursor-pointer color-primary"
                    />
                </div>
            </b-card-header>
            <b-card-body>
                <b-form-group label="Mật khẩu cũ">
                    <b-input-group>
                      <b-form-input
                      :type="passwordFieldType.OldPassword"
                      v-model="DataChangePassWord.OldPassword"
                      maxlength="100"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon.OldPassword"
                          @click="togglePasswordVisibilityOldPassword"
                        />
                      </b-input-group-append>
                    </b-input-group>
                </b-form-group>
                <b-form-group label="Mật khẩu mới">
                    <b-input-group>
                      <b-form-input
                    :type="passwordFieldType.NewPassword"
                    v-model="DataChangePassWord.NewPassword"
                    maxlength="100"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon.NewPassword"
                        @click="togglePasswordVisibilityNewPassword"
                      />
                    </b-input-group-append>
                    </b-input-group>
                </b-form-group>
                <b-form-group label="Nhập lại mật khẩu mới">
                    <b-input-group>
                      <b-form-input
                    :type="passwordFieldType.reNewPassword"
                    v-model="DataChangePassWord.reNewPassword"
                    maxlength="100"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon.reNewPassword"
                        @click="togglePasswordVisibilityreNewPassword"
                      />
                    </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-card-body>
            <template #modal-footer>
              <div class="w-100 d-flex justify-content-end">
                <b-button
                  variant="primary"
                  size="md"
                  @click="changePassWord"
                >
                  Xác nhận
                </b-button>
              </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { showResToast } from '@/@core/utils/utils'
import { initialAbility } from '@/libs/acl/config'
import { commonServices } from '@/api/common-services'
  export default {
    mixins: [togglePasswordVisibility],
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      DataChangePassWord: {
        OldPassword: null,
        NewPassword: null,
        reNewPassword: null
      },
      passwordFieldType: {
        OldPassword: 'password',
        NewPassword: 'password',
        reNewPassword: 'password',
      },
      passwordToggleIcon: {
        OldPassword: 'EyeIcon',
        NewPassword: 'EyeIcon',
        reNewPassword: 'EyeIcon',
      },
      isOpen: false
    }
  },
  watch: {
    isOpen(value) {
      if(!value) {
        this.DataChangePassWord =  {
        OldPassword: null,
        NewPassword: null,
        reNewPassword: null
      }
      this.passwordFieldType = {
        OldPassword: 'password',
        NewPassword: 'password',
        reNewPassword: 'password',
      }
      this.passwordToggleIcon = {
        OldPassword: 'EyeIcon',
        NewPassword: 'EyeIcon',
        reNewPassword: 'EyeIcon',
      }
      }
    }
  },
  methods: {
    togglePasswordVisibilityOldPassword() {
      this.passwordFieldType.OldPassword = this.passwordFieldType.OldPassword === 'password' ? 'text' : 'password'
      this.passwordToggleIcon.OldPassword = this.passwordFieldType.OldPassword === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    togglePasswordVisibilityNewPassword() {
      this.passwordFieldType.NewPassword = this.passwordFieldType.NewPassword === 'password' ? 'text' : 'password'
      this.passwordToggleIcon.NewPassword =  this.passwordFieldType.NewPassword === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    togglePasswordVisibilityreNewPassword() {
      this.passwordFieldType.reNewPassword = this.passwordFieldType.reNewPassword === 'password' ? 'text' : 'password'
      this.passwordToggleIcon.reNewPassword = this.passwordFieldType.reNewPassword === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    logout() {
      // Remove userData from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('foToken')
      localStorage.removeItem('userGolfData')
      localStorage.removeItem('currentSiteId')
      localStorage.removeItem('timeZone')
      localStorage.removeItem('timeZoneOffset')
      localStorage.removeItem('Permission')
      // Reset ability
      this.$ability.update(initialAbility)
      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
    async changePassWord() {
      if(this.DataChangePassWord.NewPassword === this.DataChangePassWord.reNewPassword) {
        const body = {
          OldPassword: this.DataChangePassWord.OldPassword,
          NewPassword:this.DataChangePassWord.NewPassword,
          ConfirmPassword:this.DataChangePassWord.reNewPassword
        }
        await commonServices.api_UAT01(body).then(res => {
          this.showResToast(res)
          if(res.Status === '200') {
            console.log('ok')
            this.hideModal()
            this.logout()
          }
        })
      } else {
        this.showToast('error', `Nhập lại mật khẩu không đúng`)
      }
      console.log(this.userData)
      console.log(this.DataChangePassWord)
    },
    hideModal() {
      this.$bvModal.hide('modal-changePassWord')
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
#modal-changePassWord {
  .block-header {
    .title-modal {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
    }
  }

  .color-primary {
    color: #114A9F;
  }
  .content-body-modal {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }


  .block {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

</style>