<template>
  <b-nav-item-dropdown
    variant="link"
    class="language-dropdown"
    right
  >
    <template #button-content>
      <b-img
        v-if="currentLocale && currentLocale.WordTranslate"
        :src="require(`@/assets/images/flags/${currentLocale.WordTranslate}.svg`)"
        style="width:22px"
        
      />
      <template v-if="currentLocale && currentLocale.Description">
        {{ currentLocale.Description }}
      </template>
    </template>

    <b-dropdown-item
      v-for="lang in listLanguages"
      :key="lang.LangId"
      @click="changeLanguage(lang.LangId)"
    >
      <b-img
        :src="require(`@/assets/images/flags/${lang.WordTranslate}.svg`)"
        style="width:22px"
        :alt="lang.Description"
      />
      <span class="ml-50">{{ lang.Description }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'
import { commonServices } from '@/api/common-services'
import { localize } from 'vee-validate'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  data() {
    return {
      listLanguages: [],
    }
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.listLanguages.find(l => l.LangId == this.$i18n.locale)
    },
  },
  created() {
    this.getListLanguages()
    // Set bản dịch validate theo ngôn ngữ đã chọn
    if (this.$i18n.locale == 1000001) {
      localize('en')
    } else {
      localize('vi')
    }
  },
  methods: {
    changeLanguage(lang) {
      localStorage.setItem('systemLanguage', lang)
      location.reload()
    },
    async getListLanguages() {
      await commonServices.getLanguages()
        .then(res => {
          if (res) {
            this.listLanguages = res.Data.LangCategory.filter(x => x.Active == true)
          }
        })
    },
  },
}
</script>

<style>

</style>
